<template>
  <div class="min-h-screen">
    <div class="lg:px-40 w-full px-5 py-12 flex flex-row bg-background-50">
      <div class="lg:w-28 lg:h-28 w-16 h-16 rounded-full">
        <img
          v-if="true"
          class="object-cover w-full h-full"
          :src="require('@/assets/img/user-default.webp')"
          alt=""
        />
        <svg
          v-else
          class="w-full h-full"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="24" fill="#E0E0E0" />
            <mask
              id="mask0"
              mask-type="alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="48"
              height="48"
            >
              <circle cx="24" cy="24" r="24" fill="#E0F1F2" />
            </mask>
            <g mask="url(#mask0)">
              <path
                d="M24.375 36.1541C11.7619 36.1541 1.5 46.416 1.5 59.029C1.5 59.578 1.9456 60.0236 2.4946 60.0236H46.2554C46.8044 60.0236 47.25 59.578 47.25 59.029C47.25 46.4159 36.9881 36.1541 24.375 36.1541Z"
                fill="#FAFAFA"
              />
              <path
                d="M24.3746 33.8804C30.4167 33.8804 35.3148 28.9823 35.3148 22.9402C35.3148 16.8981 30.4167 12 24.3746 12C18.3325 12 13.4344 16.8981 13.4344 22.9402C13.4344 28.9823 18.3325 33.8804 24.3746 33.8804Z"
                fill="#FAFAFA"
              />
            </g>
          </svg>
      </div>
      <div class="lg:px-12 px-4 lg:py-4 py-2">
        <span class="block">
          <ArticleCardLoading
            v-if="!WRITTER_READY"
            body-class="md:w-1/3 w-full h-5 mb-2"
            />
            {{ getWritterCreatedAt() }}
        </span>
        <span class="font-semibold lg:text-headline-3 text-headline-6">
          {{ fullname }}
        </span>
      </div>
    </div>
    <div class="w-full px-0 lg:py-12 py-8">
      <div class="flex flex-row lg:px-8 px-4 lg:pt-4 pt-1 lg:pb-4 pb-2">
        <div class="w-4/5 font-semibold lg:text-headline-4 text-headline-6">
          All Articles
        </div>
      </div>
      <div class="flex flex-row flex-wrap md:px-5 px-1">
        <small class="mt-10 block" v-if="ARTICLE_READY && GET_ARTICLES.length === 0"> No available article for {{ fullname }}.</small>
      </div>
      <div class="flex flex-row flex-wrap lg:px-5 px-4">
        <ArticleCardLoading
          v-show="!ARTICLE_READY"
          body-class="px-3 md:w-1/3 w-full  lg:h-40 h-20"
          v-for="index in 3"
          v-bind:key="'loader' + index"
        />
        <ArticleCard
          class="sm:px-3"
          v-show="ARTICLE_READY"
          v-for="item in GET_ARTICLES"
          :key="'article' + item.id"
          :image-src="articleImage(item.thumbnail)"
          :slug="item.article_title.replace(/ /g,'-')"
          :title="item.article_title"
          :category="item.category.article_category_name"
          :published-date="moment(item.published_at).format('DD/MM/YYYY')"
          :author="item.writter"
          :bookmarked="item.saved_articles.length > 0"
          @state-toggle="(newState) => { changeArticleSaveState(item) }"
          responsive
        />
      </div>
    </div>
  </div>
</template>

<script>
import ArticleCard from '@/components/Articles/ArticleCard.vue'
import ArticleCardLoading from '@/components/Articles/ArticleCardLoading.vue'
import { createNamespacedHelpers } from 'vuex'

const writter = createNamespacedHelpers('writter')
const article = createNamespacedHelpers('article')

export default {
  components: {
    ArticleCard,
    ArticleCardLoading
  },
  data () {
    return {
      fullname: '',
      // author: {
      //   name: 'Rama Krushna',
      //   avatar: require('@/assets/img/author-avatar-small-1.png')
      // },
      fetchOption: {
        limit: 12,
        page: 1,
        query_tag: 'category',
        query_value: '',
        filter: {
          target: 'id',
          sort: 'desc'
        },
        fullname: ''
      }
      // images: [
      //   require('@/assets/img/article-header-preview-1.jpg'),
      //   require('@/assets/img/article-header-preview-2.jpg'),
      //   require('@/assets/img/article-header-preview-3.jpg'),
      //   require('@/assets/img/article-header-preview-4.jpg'),
      //   require('@/assets/img/article-header-preview-5.jpg'),
      //   require('@/assets/img/article-header-preview-6.jpg')
      // ]
    }
  },
  watch: {
    $route (to, from) {
      if (to !== from) {
        this.initialize()
      }
    }
  },
  computed: {
    ...writter.mapGetters(['WRITTER_READY', 'GET_WRITTER']),
    ...article.mapGetters(['ARTICLE_READY', 'GET_ARTICLES'])
  },
  methods: {
    ...writter.mapActions(['getWritter']),
    ...article.mapActions(['getArticlesByWritter', 'toggleSaveArticle']),
    getRandomImage () {
      return this.images[Math.floor(Math.random() * this.images.length)]
    },
    changeArticleSaveState (article) {
      this.toggleSaveArticle({ data: { article_id: article.id } }).then(result => {
        if (result.data.id !== undefined) {
          article.saved_articles.push(result.data)
        } else {
          article.saved_articles = []
        }
      })
    },
    getWritterCreatedAt () {
      if (this.WRITTER_READY) {
        try {
          return 'Join at ' + this.moment(this.GET_WRITTER.created_at).format('DD/MM/YYYY')
        } catch (err) {
          return 'Not registered'
        }
      }
      return ''
    },
    initialize () {
      this.fullname = this.$route.params.username.replace(/-/g, ' ')
      const title = 'Algovision - ' + this.fullname
      document.title = title
      this.setMetaTag(title, `Read trending stock from ${this.fullname}`)
      this.$gtag.screenview({
        app_name: 'Algovision_WebApp',
        screen_name: 'Author'
      })
      this.$gtag.pageview(this.$route.path)
      this.fetchOption.fullname = this.$route.params.username
      this.getWritter({ data: { fullname: this.$route.params.username } })
      this.getArticlesByWritter({ data: this.fetchOption })
    }
  },
  created () {
    this.initialize()
  }
}
</script>
