<template>
  <div class="grid grid-cols-1 mt-5">
    <div class="text-center">
      <h1 style="font-size:24pt"><strong>Algovision</strong></h1>
    </div>
    <div class="text-center mt-3">
      <h1><strong>Account verification</strong></h1>
    </div>
    <div  class="text-center mt-5">
      <h2 v-if="verifying">Your account with username: {{$route.query.email}} is being verified.</h2>
      <h2 v-if="!verifying && !verification">{{ error }}</h2>
    </div>
    <div  class="text-center mt-5">
      <h3 v-if="verifying"><small>This page will redirect after the verification complete, please wait.</small></h3>
      <h3 v-if="!verifying && !verification"><small>Will automatically redirect within {{ counter }} second(s).</small></h3>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default ({
  data () {
    return {
      verifying: true,
      verification: true,
      error: '',
      counter: 5
    }
  },
  methods: {
    countRedirect () {
      setTimeout(() => {
        if (this.counter === 1) {
          this.$router.push({ name: 'route.home' })
        } else {
          this.counter--
          this.countRedirect()
        }
      }, 1000)
    }
  },
  created () {
    if (this.$route.query.email !== undefined && this.$route.query.token !== undefined) {
      this.verifying = true
      axios.post(process.env.VUE_APP_API_URL + '/v1/client/user/verify', { email: this.$route.query.email, token: this.$route.query.token })
        .then(async (result) => {
          if (result.data.data.verification !== undefined && result.data.data.verification) {
            this.verifying = false
            this.$gtag.event('event', {
              event_category: 'UserAction',
              event_label: 'UserVerified'
            })
            this.$router.push({ name: 'route.home' })
          } else {
            this.error = result.data.error
            this.verifying = false
            this.verification = false
            this.countRedirect()
          }
          // this.processing = false
        })
        .catch((err) => {
          console.log(err)
          this.error = 'Ooppss! something wrong happened.'
          this.verifying = false
          this.verification = false
          this.countRedirect()
        })
        // console.log('email to verify: ' + this.$route.query.email)
        // console.log('token to verify: ' + this.$route.query.token)
    }
  }
})
</script>
